import { render, staticRenderFns } from "./AEditor.vue?vue&type=template&id=274d69d5&scoped=true&"
import script from "./AEditor.vue?vue&type=script&lang=js&"
export * from "./AEditor.vue?vue&type=script&lang=js&"
import style0 from "./AEditor.vue?vue&type=style&index=0&id=274d69d5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274d69d5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VSheet})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
