<template>
  <v-sheet
      @click="select"
      v-click-outside="deselect"
      style="width: 100%"
      color="transparent"
      class="salvation-wrapper"
  >
    <a-editor-toolbar
        v-if="isSelected"
        @action="formatText"
        @toolbarDefaultAction="$emit('toolbarDefaultAction' , $event)"
        :display="isSelected"
        :range="selectedRange"
        :styles="styles"
        @toolbarOpened="toolbarOpened = $event"

    />
    <div
        ref="editor"
        contenteditable="plaintext-only"
        :placeholder="$t('placeholder.text')"
        @input="processInput"
        @paste="processInsert"
        @focus="$emit('edit',true)"
        @keydown="processKeydown"
        class="editor-style salvation-editor"
        :style="getStyleAsString(styles.div)"
        v-html="text || null"
    >
    </div>

  </v-sheet>
</template>

<script>

import AEditorToolbar from "@/components/AvalonEditor/Editor/aEditor/aEditorToolbar";
import editorFunctions from "@/components/AvalonEditor/Editor/aEditor/mixins/editorFunctions";
export default {
  name: "AEditor",
  mixins : [editorFunctions],
  props : {
    value : {
      type : String,
      default : '',
    },
    block : {
      type : Object,
      default() { return { config : {} } }
    },
    elementHeight : {},
    elementConfig : {
      type : Object,
      default() { return {} }
    }
  },
  components : {
    AEditorToolbar
  },
  data() {
    return {

      toolbarOpened : false,
      isSelected : false,
      edit  : false,
      text2 :  null,
      text : "",
      height : 0,
      watchHeight : false,
      selectedFontStyle : '',
      selectedRange : null,
    }
  },
  computed : {
    styles () {
      return {
        div : {
          color : '#333333',
          fontSize : '16px',
          fontWeight : '600'
        },
        p : {
          color : this.GET_AVALON_COLOR(this.avalonBlockConfig(this.block, 'text_color' )) ,
        },
        h1 : {
          fontSize : '38px',
          color : this.GET_AVALON_COLOR(this.avalonBlockConfig(this.block, 'heading_color' )) ,
          'font-weight' : '400'
        },
        h2 : {
          fontSize : '32px',
          color : this.GET_AVALON_COLOR(this.avalonBlockConfig(this.block, 'heading_color' )) ,
          fontWeight : '400'
        },
        h3 : {
          fontSize : '22px',
          color : this.GET_AVALON_COLOR(this.avalonBlockConfig(this.block, 'heading_color' )) ,
          fontWeight : '400'
        },
        h4 : {
          fontSize : '18px',
          color : this.GET_AVALON_COLOR(this.avalonBlockConfig(this.block, 'heading_color' )) ,
          fontWeight : '400'
        },
        h5 : {
          fontSize : '14px',
          color : this.GET_AVALON_COLOR(this.avalonBlockConfig(this.block, 'heading_color' )) ,
          fontWeight : '400'
        },
        h6 : {
          fontSize : '12px',
          color : this.GET_AVALON_COLOR(this.avalonBlockConfig(this.block, 'heading_color' )) ,
          fontWeight : '400'
        },
      }
    },
    element() {
      return this.$refs.editor
    }
  },
  watch : {
    'block.config' : {
      handler() {
        this.applyStyles()
      },
      deep : true
    },
    elementConfig : {
      handler() {
        this.getHeight()
      },
      deep : true
    },
    value() {
      if ( this.value !== this.text ) {
        this.text = this.value
      }
    },
    isSelected(value) {
      if ( !value ) {
        this.saveText();
        this.edit = false ;
        this.$emit('edit' , false)
      }
    },
    height() {
      if ( this.watchHeight && this.height > this.elementHeight ) {
        this.$emit('updateHeight', this.height )
      } else {
        this.watchHeight = true
      }
    },
    toolbarOpened() {
      this.$emit('toolbarOpened' , this.toolbarOpened)
    }
  },
  methods : {

    select() {
      this.isSelected = true ;
      this.$emit('edit' , true)
    },
    deselect() {
      if ( !this.toolbarOpened ) {
        this.isSelected = false
        this.$emit('edit' , false)
      }
    },



    formatText(data) {

      var selection = window.getSelection();
      let range = selection.getRangeAt(0)

      this.processFormating( range ,data.item , data.unset )

    },


    saveText() {

      let html = this.element.innerHTML
      let text = this.element.innerText

      if ( this.text === html || this.isSelected  ) {
        return
      }
      if ( text.length === 1 && ( text.charCodeAt(0) === 10 || text.charCodeAt(0) === 160 )  ) {
        this.element.replaceChildren()
        this.text = ''
      } else {
        this.text = html
      }
      this.$emit('input',this.text)

    },
    processInsert() {
      // console.log($event)
    },
    handleEnter($event) {
      if (!$event.shiftKey) {
        $event.preventDefault()
        this.newLine(this.element)
      }

    },
    processKeydown($event) {

      let text = this.element.innerHTML
      const systemKeys = ['U+0008' , 'U+0009' , 'U+001B']

      if ( text.length === 0 || ( text.length === 1 && ( text.charCodeAt(0) === 10 || text.charCodeAt(0) === 160 ) ) ) {
        $event.preventDefault()

        if ( $event.keyIdentifier.includes('U+') && !systemKeys.includes($event.keyIdentifier) ) {

          let paragraph = document.createElement('p')
          paragraph.innerText = $event.key
          this.element.appendChild(paragraph)

          let selection = document.getSelection()
          let range = document.createRange()
          range.setStart(paragraph,1)
          range.collapse()
          selection.removeAllRanges()
          selection.addRange(range)
        }
        return
      }

      if ( $event.key === 'Enter' ) {
        this.handleEnter($event)
      }



    },
    processInput($event) {
      $event.preventDefault()
      // let text = this.element.innerHTML
      // this.notify( 'inputProcessor: ' +  this.element.innerText.length)

      // if ( text.length === 1 && ( text.charCodeAt(0) === 10 || text.charCodeAt(0) === 160 )  ) {
      //
      // }
      this.getHeight()
    },
    parseHtml(element) {

      let tag = element.tagName.toLowerCase()

      if ( this.styles[tag] ) {
        Object.keys(this.styles[tag]).forEach( style => {
          // if ( !element.style[style] ) {
           element.style[style] = this.styles[tag][style]
          // }
        })
      }

      element.children.forEach(child=> {
        this.parseHtml(child)
      })

    },
    applyStyles() {
      setTimeout(()=> {
        this.$nextTick(()=> {
          this.parseHtml(this.element)
        })
      } , 50)

    },
    getHeight() {
      let height = this.element.clientHeight
      if ( this.elementConfig.padding_top) {
        height += this.elementConfig.padding_top
      }
      if ( this.elementConfig.padding_bottom) {
        height += this.elementConfig.padding_bottom
      }
      this.height = height
    }

  },
  mounted() {
    this.text = this.value

    this.applyStyles()
    this.getHeight()

  }
}
</script>

<style scoped>
.editor-style  {
  outline: none !important;
}

.editor-style[contenteditable]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
  opacity: 0.7;
  display: block; /* For Firefox */
}



</style>