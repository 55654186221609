<template>
  <div ref="toolbarWrapper"  class="salvation-toolbar">
    <v-fade-transition >
      <v-sheet  v-if="display" ref="toolbar"  class="salvation-toolbar toolbar" color="transparent">
        <v-sheet
            :style="`border : 1px solid ${wsBACKGROUND} ; color : ${wsACCENT}`"
            class="d-flex align-center wsRoundedLight  px-3 "
            light
        >
          <ft-select
              ref="toolbarWrapper"
              @input="formatHeading"
              v-model="selectedFontStyle"
              :items="fontStyles"

          >
            <template #default="{text}">
              <v-sheet color="transparent" min-width="120" class="d-flex align-center">
                <h5 class="text-no-wrap" :style="`color  : ${wsACCENT}`">{{ text || $t('font.p') }}</h5>
                <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
              </v-sheet>
            </template>

          </ft-select>

<!--          <v-menu ref="toolbarWrapper" class="salvation-toolbar" v-model="colorDropdown" :close-on-content-click="false" offset-y>-->
<!--            <template v-slot:activator="{attrs,on}">-->
<!--              <v-btn class="px-0 mx-0" :color="wsACCENT" v-bind="attrs" v-on="on" text min-width="35" width="35">-->
<!--                <v-icon>mdi-circle</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <v-sheet  width="350" class="pa-2 salvation-toolbar">-->
<!--              <a-color-picker-->
<!--                  @input="updateColor"-->
<!--                  v-model="selectedColor"-->
<!--              />-->
<!--            </v-sheet>-->
<!--          </v-menu>-->


          <v-divider class="mx-2 my-2" :style="`border-color : ${wsBACKGROUND}`" vertical />

          <v-btn
              v-for="item in toolbarItemsSelect" :key="item.value"
              @click="formattingAction(item)"
              :color="wsACCENT"
              :style="toolbarItems[item.value] ? `background-color : ${wsBACKGROUND}` : '' "
              class="px-0 mx-0"
              min-width="35"
              elevation="0"
              width="35"
              text
          >
            <v-icon>{{  item.icon  }}</v-icon>
          </v-btn>

          <v-divider class="mx-2 my-2" :style="`border-color : ${wsBACKGROUND}`" vertical />

          <ws-tooltip
              v-for="item in toolbarDefaultItems" :key="item.value"
              :text="$t(item.tooltip)"
          >
            <v-btn
                @click="$emit('toolbarDefaultAction' , item.value)"
                :color="item.value !== 'delete' ? wsACCENT : wsWARNING"
                class="px-0 mx-0"
                min-width="35"
                elevation="0"
                width="35"
                text
            >
              <v-icon>{{  item.icon  }}</v-icon>
            </v-btn>
          </ws-tooltip>



        </v-sheet>
      </v-sheet>
    </v-fade-transition>
  </div>

</template>

<script>
import editorFunctions from "@/components/AvalonEditor/Editor/aEditor/mixins/editorFunctions";
export default {
  name: "aEditorToolbar",
  mixins : [editorFunctions],
  props : {
    display : {
      type : Boolean,
      default : false
    },
    styles : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      mouseOnToolbar : false,
      selectedColor : '',
      colorDropdown : false,
      range : null,
      rangeBuffer : null,
      selectedFontStyle : '',
      toolbarItems : {
        strong : false,
        italic : false,
        underline : false,
        link : false,
        'align-left' : false,
        'align-center' : false,
        'align-right' : false,
        'align-justify' : false,
      },
      toolbarItemsSelect : [
        { value : "strong"         ,   tag : "strong"   ,    rootTag : false   ,                                                  icon : "mdi-format-bold"          } ,
        { value : "italic"         ,   tag : "em"       ,    rootTag : false   ,                                                  icon : "mdi-format-italic"        } ,
        { value : "underline"      ,   tag : "span"     ,    rootTag : false   ,    style    : 'text-decoration : underline'  ,   icon : "mdi-format-underline"     } ,
        // { value : "link"           ,   tag : "a"        ,    rootTag : false                                                  ,   icon : "mdi-link"                 } ,
        { value : "align-left"     ,                 rootTag : true    ,    style    : { textAlign  : 'left'    }            ,   icon : "mdi-format-align-left"    } ,
        { value : "align-center"   ,                 rootTag : true    ,    style    : { textAlign  : 'center'  }            ,   icon : "mdi-format-align-center"  } ,
        { value : "align-right"    ,                 rootTag : true    ,    style    : { textAlign  : 'right'  }            ,   icon : "mdi-format-align-right"   } ,
        { value : "align-justify"  ,                 rootTag : true    ,    style    : { textAlign  : 'justify'}            ,   icon : "mdi-format-align-justify" } ,
      ],
      toolbarDefaultItems : [
        { value : 'edit' , icon : 'mdi-pencil' , tooltip : 'avalon.element.edit' },
        { value : 'duplicate' , icon : 'mdi-content-copy' , tooltip : 'avalon.element.copy' },
        { value : 'layer-' , icon : 'mdi-flip-to-back'  , tooltip : 'avalon.element.flip.back' },
        { value : 'layer+' , icon : 'mdi-flip-to-front' , tooltip : 'avalon.element.flip.front' },
        { value : 'delete' , icon : 'mdi-delete' , tooltip : 'avalon.element.delete' },
      ]
    }
  },
  computed : {
    fontStyles() {
      return [
        { text : this.$t('font.h1') , value : 'h1' },
        { text : this.$t('font.h2') , value : 'h2' },
        { text : this.$t('font.h3') , value : 'h3' },
        { text : this.$t('font.h4') , value : 'h4' },
        { text : this.$t('font.h5') , value : 'h5' },
        { text : this.$t('font.h6') , value : 'h6' },
        { text : this.$t('font.p')  , value : 'p' }
      ]
    }
  },
  watch : {
    selectionChange() {
      this.processSelection()
    },
    colorDropdown() {
      this.$emit('toolbarOpened' , this.colorDropdown)
    }
  },
  methods : {
    updateColor() {

    },
    formatHeading($event) {
      let data = {
        tag : $event,
        rootTag : true,
        value : $event ,
        style :  this.styles[$event],
        eraseStyle : $event === 'p'
      }
      this.formattingAction(data , true )
    },
    formattingAction(item, customUnset = false , unsetValue = false ) {
      let data = {
        item  : item,
        unset : !customUnset ? this.toolbarItems[item.value] : unsetValue
      }
      this.$emit('action' , data)
    },
    processSelection(selection) {

      this.range = selection.getRangeAt(0)
      this.checkToolbarButtons()

    },
    checkToolbarButtons() {
      this.toolbarItemsSelect.forEach(item => {
        if ( item.tag && !item.style && !item.rootTag ) {
          this.toolbarItems[item.value] = this.searchTag(this.range, item.tag)
        }
        else if ( item.style && item.rootTag ) {
          this.toolbarItems[item.value] = this.searchRootTagStyle(this.range, item.style)
        }
      })
      this.selectedFontStyle = this.findRootTagName(this.range.startContainer) || 'p'
    },

    selectStart($event) {
      if (  this.checkToolbarSelection($event.target) ) {
        $event.preventDefault()
      }
    },
    processSelectionChange() {
      let selection = document.getSelection()
      if ( !selection.anchorNode) {
        selection.removeAllRanges()
        selection.addRange(this.range)
      }
      this.processSelection(selection)

    },
  },
  mounted() {
    document.addEventListener('selectstart', this.selectStart )
    document.addEventListener('selectionchange', this.processSelectionChange )
  },
  beforeDestroy() {
    document.removeEventListener('selectstart', this.selectStart )
    document.removeEventListener('selectionchange' , this.processSelectionChange )
  }
}
</script>

<style scoped>

.toolbar {
  margin-top: -50px;
  position: absolute;
}
</style>