export default {
    methods: {
        // general functions
        isSingleNode(range) {

            if ( range.commonAncestorContainer.classList ) {
                return !range.commonAncestorContainer.classList.contains('salvation-editor') && range.startContainer.isEqualNode(range.endContainer) && range.startContainer.childNodes.length === 0
            }

            return  range.startContainer.isEqualNode(range.endContainer) && range.startContainer.childNodes.length === 0
        },
        isSingleRootNode(range) {
            return !range.commonAncestorContainer.classList ||  !range.commonAncestorContainer.classList.contains('salvation-editor')
        },
        isRootNode(element) {
            if (element.parentElement) {
                if ( element.parentElement.classList ) {
                    if ( element.parentElement.classList.contains('salvation-editor') ) {
                        return true
                    }
                }
            }
            return false
        },
        findTag(child, tag) {

            let node = child;
            let stopSearch = false

            if ( node.innerHTML ) {
                if ( node.innerHTML.includes(`<${tag}>`) ) {
                    return true
                }
            }

            while (node !== null && !stopSearch) {
                if ( node.classList ) {
                    stopSearch = node.classList.contains('salvation-editor')
                }
                if (node.nodeName.toLowerCase() === tag) {
                    return true;
                }

                node = node.parentNode;
                if (!node || node.nodeName === '#document') {
                    return false
                }
            }

            node = child;
            stopSearch = false

            while (node !== null && !stopSearch) {

                if ( node.classList ) {
                    stopSearch = node.classList.contains('salvation-editor')
                }
                if (node.nodeName.toLowerCase() === tag) {
                    return true;
                }

                node = node.parentElement;
                if (!node || node.nodeName === '#document') {
                    return false
                }
            }


            return false;
        },
        isChildOfParentNode(child, parent) {
            let node = child.parentNode;
            let stopSearch = false

            while (node !== null && !stopSearch) {
                if ( node.classList ) {


                stopSearch = node.classList.contains('salvation-editor')
                }
                if (node === parent) {
                    return true;
                }
                node = node.parentNode;
                if (!node || node.nodeName === '#document') {
                    return false
                }
            }
            return false;
        },
        findRootTag(child ) {
            let node = child.parentNode;
            let stopSearch = false

            let result = null
            while (node !== null && !stopSearch) {

                if ( node.classList ) {
                    stopSearch = node.classList.contains('salvation-editor')

                    if (!node.classList.contains('salvation-editor')) {
                        result = node
                    }

                }



                node = node.parentNode;
                if (!node || node.nodeName === '#document') {
                    return null
                }
            }
            return result;
        },
        findRootTagName(child ) {
            let element = this.findRootTag(child)
            if ( element ) {
                return element.tagName.toLowerCase()
            }
            return null;
        },

        // Formatting functions
        processFormating(range , item , unset ) {

            let { startContainer, startOffset,  endContainer  } = range

            // Process Root tag formatting
            if ( item.rootTag ) {
                return this.processRootTagFormatting(range , item , unset)
            }

            // Process regular tag formatting
            let extr = range.extractContents()

            let tagStart = `<${item.tag}${item.style ? ' style="' + item.style + '"' : ''}>`
            let tagEnd = `</${item.tag}>`

            if ( this.isSingleRootNode(range)) {
                let html = ''
                extr.childNodes.forEach(child => {
                    html += child.nodeName === '#text' ? child.textContent : child.outerHTML
                })

                html = html.replaceAll(tagStart,'')
                html = html.replaceAll(tagEnd,'')
                if ( !unset ) {
                    html = tagStart + html + tagEnd
                } else {

                    if ( extr.childNodes.length < 2 ) {
                        let rootNode = this.findRootTag(range.startContainer)

                        let parent = startContainer.nodeName === item.tag ? startContainer : startContainer.parentElement
                        html = tagEnd + html + tagStart

                        html = parent.innerHTML.slice(0, startOffset) + html + parent.innerHTML.slice(startOffset)

                        let emptyTag = this.getNodeFullTag(parent) + `</${parent.tagName.toLowerCase()}>`
                        parent.outerHTML
                            = this.getNodeFullTag(parent) + html + `</${parent.tagName.toLowerCase()}>`

                        if ( rootNode ) {
                            if ( rootNode.innerHTML ) {
                                rootNode.innerHTML = rootNode.innerHTML.replaceAll(emptyTag,'')
                            }
                        }

                        return
                    }

                    let parent = startContainer.innerHTML ? startContainer : startContainer.parentElement
                    if (this.isRootNode(parent)) {
                        parent.innerHTML = parent.innerHTML.slice(0, startOffset) + html + parent.innerHTML.slice(startOffset)
                    } else {
                        parent.insertAdjacentHTML("afterend",html )
                    }

                    return
                }

                range.insertNode(this.htmlToElement(html))
                document.getSelection().addRange(range)

            } else {

                extr.childNodes.forEach((child, index) => {

                    if (!child.innerHTML) {
                        return
                    }

                    let rootTagStart  = this.getNodeFullTag(child)
                    let rootTagEnd  = `</${child.tagName.toLowerCase()}>`

                    let childHtml = child.innerHTML
                    childHtml = childHtml.replaceAll(tagStart, '')
                    childHtml = childHtml.replaceAll(tagEnd, '')

                    if ( !unset ) {
                        childHtml = tagStart + childHtml + tagEnd
                    }

                    if (index === 0 ) {
                        startContainer.parentElement.insertAdjacentHTML("beforeend",   tagEnd + childHtml)
                    } else {

                        childHtml = index < (extr.childNodes.length - 1) ? rootTagStart + childHtml + rootTagEnd : childHtml
                        const position = index < (extr.childNodes.length - 1) ? "beforebegin" : "afterbegin"
                        if ( endContainer.innerHTML) {
                            endContainer.insertAdjacentHTML(position , childHtml)
                        } else {
                            endContainer.parentElement.insertAdjacentHTML(position , childHtml)
                        }
                    }

                })
            }


        },
        processRootTagFormatting(range , item) {

            const {startContainer , endContainer } = range
            let endContainerRoot = this.findRootTag(endContainer)
            let element = this.findRootTag(startContainer)


            if ( this.isSingleRootNode(range) ) {
                this.formatRootTag(element ,item)
                let selection = document.getSelection()
                selection.removeAllRanges()
            } else {
                let array = []
                while (  element !== null ) {
                    array.push(element)
                    element = element.nextSibling
                    if ( element === endContainerRoot || element === endContainer ) {
                        array.push(element)
                        element = null
                    }
                }
                array.forEach(element => {
                    this.formatRootTag(element ,item)
                })
            }

        },
        formatRootTag(element , item ) {

            if ( element ) {
                element.outerHTML = this.formHtmlElementString( element , item )
            }
            return element


        },
        // Tollbar checks
        searchTag(range , tag) {
            let content = range.cloneContents()

            let result = false

            if (content.childNodes.length < 2) {
                result = this.findTag(range.startContainer , tag)
            }

            content.childNodes.forEach(el=> {
                let search = this.findTag(el , tag)
                if ( search ) {
                    result = true
                }
            })

            return result
        },
        searchRootTagStyle(range , styles) {
            let element = this.findRootTag(range.startContainer)
            let result = true
            Object.keys(styles).forEach(key=> {
                if ( element.style[key] !== styles[key]) {
                    result =  false
                }
            })
            return result
        },

        checkToolbarSelection(child) {
            let node = child
            let stopSearch = false


            let result = false
            while (node !== null && !stopSearch) {
                if ( node.classList) {
                    stopSearch = node.classList.contains('salvation-wrapper')
                    if ( node.classList.contains('salvation-toolbar') ) {
                        return true
                    }
                }

                node = node.parentNode;

                if (!node || node.nodeName === '#document') {
                    return false
                }
            }
            return result;
        },
        // Node parsing functions
        getNodeFullTag(node) {
            if ( node.tagName ) {
                return node.outerHTML.replace(node.innerHTML + `</${node.tagName.toLowerCase()}>` , '')
            }
            return null
        },
        getElementStyleAsString(element) {
            let tag = this.getNodeFullTag(element)
            let index = tag.indexOf('style=')
            let style = tag.slice(index + 7).slice(0,-2)
            return style
        },
        getElementStyleAsArray(element) {
            let styles = this.getElementStyleAsString(element)
            let array = []
            let buffer = styles.split(';')
            buffer.forEach(item => {
                let split = item.split(':')

                if ( split.length === 2) {
                    array.push ({ [split[0].replaceAll(' ','')] : split[1].replaceAll(' ','') } )
                }
            })
            return array
        },
        // Technical
        formHtmlElementString(element , item) {

            let html = element.innerHTML
            let tag = item.tag || element.tagName.toLowerCase()
            this.getElementStyleAsArray(element)

            Object.keys(item.style).forEach(key=>{
                element.style[key] = item.style[key]
            })
            if (item.eraseStyle) {
                style = false
            }
            let style = this.getElementStyleAsString(element)

            return `<${tag}${style ? ' style="' + style + '"' : ''}>${html}</${tag}>`

        },
        htmlToElement(html) {
            var template = document.createElement('template');
            template.innerHTML = html;
            return template.content.firstChild;
        },
        newLine(element) {
            document.execCommand('insertParagraph', false);

            let children = element.getElementsByTagName('div')

            if ( children.length > 0 ) {
                let paragraph = document.createElement('p')
                paragraph.innerText = "\u200B"
                element.replaceChild(paragraph ,children[0])

                let selection = document.getSelection()
                let range = document.createRange()
                range.setStart(paragraph,0)
                range.collapse()
                selection.removeAllRanges()
                selection.addRange(range)
            }
        },
        // Other
        getStyleAsString(style) {
            let result = ''
            Object.keys(style).forEach(key => {
                result += `${key} : ${style[key]} ;`
            })
            return result
        }
    }

}